import { template as template_5810d0098b5d4524ab55554ba11bcfb8 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const ManagePage: TOC<unknown> = template_5810d0098b5d4524ab55554ba11bcfb8(`
  <div class="px-8 py-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManagePage);
