import { template as template_8be1e95a252b4fdd87748c72d165ea6c } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import { AlertMessage } from 'district-ui-client/components/alert-message';
import { SideNav } from 'district-ui-client/components/side-nav';
import type SubscriptionTypeRoute from 'district-ui-client/routes/subscription-type';
interface Signature {
    Args: {
        model: ModelFor<SubscriptionTypeRoute>;
    };
}
export const SubscriptionTypePage: TOC<Signature> = template_8be1e95a252b4fdd87748c72d165ea6c(`
  <SideNav class="min-h-0 flex-shrink flex-grow" @subscriptionType={{@model.subscriptionType}}>
    <AlertMessage />
    {{outlet}}
  </SideNav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(SubscriptionTypePage);
