import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'

/**
 * This component renders a match user table row.
 * I kept it intentionally unscoped (teacher|student) so that we can use this component
 * in both places.
 *
 * @class MatchTableRowComponent
 * @property {CleverTeacherModel|CleverStudentModel} data - the cleverRecord
 * @property {Array} blakeUserOptions - the data needed to populate the dropdown
 * @property {function} matchAction - action to match a user
 * @property {function} resetAction - action to reset a user match
 */
export default class MatchTableRowComponent extends Component {
  @service clever

  @service flashQueue

  @service log

  @service intl

  @tracked
  highlight = false

  // 'student' or 'teacher'
  userType = null

  get cleverRecord() {
    return this.args.data
  }

  get isResetButtonDisabled() {
    return this.args.disableMatchButtons || this.isMatchInProgress
  }

  get isSubmitButtonDisabled() {
    return this.args.disableMatchButtons || !this.selectedOption || this.isMatchInProgress
  }

  get buttonText() {
    if (this.isCreateOptionSelected) {
      return this.intl.t('clever.createWord')
    } else {
      return this.intl.t('clever.matchWord')
    }
  }

  _getFlashMessagesForBlakeUserValue(messageType, cleverUser, blakeUser) {
    const { intl } = this
    const copyPrefix = `clever.flashMessages.${this.userType}.${messageType}`
    const cleverUserName = cleverUser.fullName
    const blakeUserName = blakeUser?.fullName || ''

    return {
      success: intl.t(`${copyPrefix}.success`, { cleverUserName, blakeUserName }),
      fail: intl.t(`${copyPrefix}.fail`, { cleverUserName, blakeUserName }),
    }
  }

  // The option selected in the match dropdown
  @tracked _selectedOption = null

  get selectedOption() {
    const { allOptions, _selectedOption } = this

    /* A previously selected option may no longer be a valid option anymore, if it was matched in a dropdown on a
     * different row. So the find() call is to check that the option selected is within the available options.
     */
    return allOptions.find((option) => option.value === _selectedOption?.value)
  }

  set selectedOption(value) {
    this._selectedOption = value
  }

  get isCreateOptionSelected() {
    return this.selectedOption?.value === 'create'
  }

  get selectedBlakeUserId() {
    if (this.isCreateOptionSelected) return null
    return this.selectedOption?.value ?? null
  }

  @action
  optionClick(option) {
    this.selectedOption = option
  }

  @tracked isMatchInProgress = false

  match = async () => {
    this.isMatchInProgress = true
    await this.args.matchAction(this.cleverRecord, this.isCreateOptionSelected ? 'create' : this.selectedBlakeUserId)
    this.isMatchInProgress = false
  }

  reset = async () => {
    this.isMatchInProgress = true
    await this.args.resetAction(this.cleverRecord)
    this.isMatchInProgress = false
  }

  /**
   * set the background colour of the row when we open the dropdown to keep the attention
   * on the selected row
   * @returns void
   */
  @action
  dropdownOpen() {
    this.highlight = true
  }

  /**
   * remove the background colour of the row when we open the dropdown
   * @returns void
   */
  @action
  dropdownClose() {
    this.highlight = false
  }
}
