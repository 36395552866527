// This error page will be rendered inside the application / subscription-type template, it should have a navbar and sidenav
import { template as template_72bd3e670e7f49c9b8789d1276cb0072 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type Controller from '@ember/controller';
import ErrorMessage from 'district-ui-client/components/error-message';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        controller: Controller & {
            subscriptionType: SubscriptionType;
        };
    };
}
export const SubscriptionTypeErrorRouteTemplate: TOC<Signature> = template_72bd3e670e7f49c9b8789d1276cb0072(`
  <ErrorMessage data-test-subscription-type-error @subscriptionType={{@controller.subscriptionType}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(SubscriptionTypeErrorRouteTemplate);
