import { template as template_d5d1bbf777b64c13884902fc52d809bf } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { ChartsCourseProgressAverages } from 'district-ui-client/components/charts/course-progress/averages/component';
import type ReSpellingProgressAveragesRoute from 'district-ui-client/routes/reporting/ui-scope/re/spelling-progress/averages';
interface Signature {
    Args: {
        model: ModelFor<ReSpellingProgressAveragesRoute>;
    };
}
export const ReportingReSpellingProgressAveragesRouteTemplate: TOC<Signature> = template_d5d1bbf777b64c13884902fc52d809bf(`
  <DetailedPageWrapper @empty={{not @model.spellingProgressAverages}}>
    {{#each @model.spellingProgressAverages.course_progress_averages as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressAverages
          class="h-full"
          @data={{gradeData}}
          @precinct={{@model.precinct}}
          @product={{@model.product}}
          @tooltip={{t "reporting.tooltips.spellingProgressAverages"}}
          @title={{t "reporting.chartTitles.spellingProgressAverages"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingReSpellingProgressAveragesRouteTemplate);
