import { template as template_02accbd0af024973bf135920b192f01d } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const CleverPage: TOC<unknown> = template_02accbd0af024973bf135920b192f01d(`
  <div class="mx-8 my-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(CleverPage);
