import { template as template_6367a425f56c4f7e9226ad3729e7596a } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import LoadingSpinner from 'district-ui-client/components/loading-spinner';
import RouteTemplate from 'ember-route-template';
export const LoadingRouteTemplate: TOC<unknown> = template_6367a425f56c4f7e9226ad3729e7596a(`<LoadingSpinner />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(LoadingRouteTemplate);
