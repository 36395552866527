import { template as template_85008d37280a453499937380f8617ffc } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_85008d37280a453499937380f8617ffc(`
    <div class="flex-shrink flex-grow overflow-y-auto px-8 py-10">
      {{outlet}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
