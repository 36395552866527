import Controller from '@ember/controller'
import { service } from '@ember/service'
import { alias } from '@ember/object/computed'
import { later } from '@ember/runloop'
import { set, action, computed } from '@ember/object'
import { timeout, waitForProperty, task, didCancel } from 'ember-concurrency'

export const WAIT_FOR_TASKS_TIMEOUT = 1000

export default class TeachersController extends Controller {
  @service
  clever

  @service
  flashQueue

  @service
  log

  @service
  intl

  @service
  store

  @service('clever/teacher-match-error')
  teacherMatchError

  queryParams = ['tab', 'currentPage', 'filter']

  @alias('tableConfig.filtering.tabFilterValue')
  tab

  @alias('tableConfig.pagination.currentPage')
  currentPage

  @alias('tableConfig.filtering.filterFieldValue')
  filter

  get school() {
    return { name: 'school' }
  }

  get areTeacherMatchesDone() {
    return this.model.cleverSchool.areTeacherMatchesDone
  }

  _getFlashMessagesForBlakeUserId(messageType, cleverUser, blakeUserId) {
    const blakeUser = blakeUserId ? this.clever.getBlakeTeacherById(blakeUserId) : undefined

    const { intl } = this
    const copyPrefix = `clever.flashMessages.teacher.${messageType}`
    const cleverUserName = cleverUser.fullName
    const blakeUserName = blakeUser?.fullName || ''

    return {
      success: intl.t(`${copyPrefix}.success`, { cleverUserName, blakeUserName }),
      fail: intl.t(`${copyPrefix}.fail`, { cleverUserName, blakeUserName }),
    }
  }

  /**
   * Filters all cleverTeachers by subscriptionType
   * @property
   * @returns {Ember.Array}
   */
  @computed('model.{cleverSchool.cleverTeachers.[],subscriptionType}')
  get cleverTeachers() {
    const { subscriptionType } = this.model
    const { cleverTeachers } = this.model.cleverSchool

    return cleverTeachers.filter((cleverTeacher) => {
      return (
        (subscriptionType === 'reading' && cleverTeacher.readingCleverApp) ||
        (subscriptionType === 'maths' && cleverTeacher.mathsCleverApp)
      )
    })
  }

  @computed('tableConfig.tabCounts.{unmatched,matched}')
  get tabOptions() {
    const { intl } = this
    const { unmatched, matched } = this.tableConfig.tabCounts
    return [
      {
        id: 'unmatched',
        title: intl.t('clever.teacherTabs.unmatched', { count: unmatched }),
        route: 'clever.match.schools.teachers',
        query: { tab: 'unmatched' },
      },
      {
        id: 'matched',
        title: intl.t('clever.teacherTabs.matched', { count: matched }),
        route: 'clever.match.schools.teachers',
        query: { tab: 'matched' },
      },
    ]
  }

  get blakeTeachers() {
    return this.store.peekAll('clever/teacher').filter((t) => t.get('school.id') === this.model.blakeSchoolId)
  }

  /**
   * Caution - Various properties of this object are aliased to query params in this file. This object is used by and
   * has properties set in by several components down the stack.
   */
  tableConfig = {
    tabCounts: {
      unmatched: 0,
      matched: 0,
    },
    pagination: {
      currentPage: 1,
      perPage: 20,
    },
    filtering: {
      tabFilterValue: 'unmatched',
      filterColumns: ['fullName'],
      filterFieldValue: '',
      filterFunctions: {
        doFilterForTab(unfilteredCleverTeachers) {
          // because we want to get the counts, we need to do all filters.
          const activeTab = this.dtoConfig?.filtering?.tabFilterValue
          const unmatched = unfilteredCleverTeachers.filter((teacher) => !teacher.matched)
          const matched = unfilteredCleverTeachers.filter((teacher) => teacher.matched)
          const tabCounts = { unmatched: unmatched.length, matched: matched.length }
          const configSetter = this.setInConfigAction
          later(() => {
            configSetter('tabCounts', tabCounts)
          })
          if (activeTab === 'unmatched') return unmatched
          if (activeTab === 'matched') return matched
          return unmatched
        },
      },
    },
    sorting: { columns: [] },
  }

  /**
   * A replacement for ember-concurrency's deprecated "task groups".
   * It allows any of the individual match related tasks to be executed sequentially. This is important as
   * we specifically need to ensure that requests to create/delete records are not carried out at the same time as any
   * updates are requested for these records.
   */
  matchTask = task({ enqueue: true }, async ({ taskType, cleverTeacher, blakeTeacherId }) => {
    switch (taskType) {
      case 'resetMatch':
        await this.resetMatchTask.perform(cleverTeacher)
        break
      case 'createMatch':
        await this.createMatchTask.perform(cleverTeacher, blakeTeacherId)
        break
      default:
        return
    }
    // Wait for a bit, to make sure there's time for further tasks to be added to queue, before requesting an update
    if (this.matchTask.numQueued === 0) await this.timeoutHandler(WAIT_FOR_TASKS_TIMEOUT)
    // If this is the last reset in this queue, fetch updated sync state for school & fetch new match records.
    // Match records need to be fetched in case gravity performed automatching
    if (this.matchTask.numQueued === 0) {
      await this.waitForSchoolUpdate.perform()
      await this.waitForMatchesUpdate.perform()
    }
  })

  // Allows for easy stubbing in tests
  timeoutHandler(ms) {
    return timeout(ms)
  }

  /**
   * Deletes a match record and performs the update tasks when things have settled
   */
  resetMatchTask = task(async (cleverTeacher) => {
    if (cleverTeacher?.matched) {
      const matchRecord = await cleverTeacher.cleverTeacherMatch
      await matchRecord?.destroyRecord()
    }
  })

  /**
   * Creates a match record (and a blake teacher if necessary) and performs the update tasks when settled
   */
  createMatchTask = task(async (cleverTeacher, blakeTeacherId) => {
    if (cleverTeacher?.matched === false) {
      if (blakeTeacherId) {
        await this.clever.matchCleverTeacherToBlakeTeacher(cleverTeacher, blakeTeacherId)
      } else {
        const { cleverSchool } = this.model
        await this.clever.createBlakeTeacherFromCleverData(cleverTeacher, cleverSchool)
      }
    }
  })

  /**
   * Fetches latest clever-school sync state, and polls until it is in any state that is not automatching teachers
   */
  waitForSchoolUpdate = task(async () => {
    const { cleverDistrictId, cleverSchoolId } = this.model
    // Retreive latest clever-school record, for latest/updated sync state
    const cleverSchool = await this.clever.fetchCleverSchool(cleverDistrictId, cleverSchoolId)

    // Kicks off the poll task if not yet started - but dont yield the task instance returned
    this.clever.pollForCleverSchool.perform(cleverSchool)
    // Instead just wait for the clever school to indicate it's done with teacher matching work.
    await waitForProperty(cleverSchool, 'isTeacherMatchingInProgress', false)
  })

  /**
   * Fetches latest clever match records for a school. This should be called whenever the backend might have performed
   * any automatching (in this case, after any create record requests)
   */
  waitForMatchesUpdate = task(async () => {
    const { store } = this
    const { cleverSchoolId, blakeSchoolId } = this.model
    const cleverTeacherRecords = store.query('clever/clever-teacher', { scope: `clever-schools/${cleverSchoolId}` })
    const teacherMatchRecords = store.query('clever/teacher', {
      scope: `schools/${blakeSchoolId}`,
      include: 'clever-teacher-match',
    })
    await Promise.all([cleverTeacherRecords, teacherMatchRecords])
  })

  /**
   * A task to create & match multiple records at once. After which, it will perform the necessary update tasks,
   * followed by an onComplete callback (where the bulk modal could be closed, for example)
   */
  bulkCreateMatchTask = task({ drop: true }, async (checkedTeachers, { onComplete, completeDelay } = {}) => {
    const { clever, flashQueue, log, intl } = this
    const { cleverSchool } = this.model

    const flashMessages = {
      success: intl.t('clever.flashMessages.teacher.bulkCreate.success'),
      complete: intl.t('clever.flashMessages.teacher.bulkCreate.complete'),
      fail: intl.t('clever.flashMessages.teacher.bulkCreate.fail'),
    }

    try {
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await clever.bulkCreateBlakeTeachersFromCleverData(cleverSchool, checkedTeachers)
      flashQueue.addSuccess({ subtitle: flashMessages.complete })
    } catch (err) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: err })
    }
    await this.waitForSchoolUpdate.perform()
    await this.waitForMatchesUpdate.perform()

    if (completeDelay) await this.timeoutHandler(completeDelay)
    onComplete?.()
  })

  get remedyTask() {
    return this.teacherMatchError.remedy
  }

  get disableMatchButtons() {
    // Disable match/reset buttons while any update tasks are running OR if a bulk match is happening OR we've queued up
    // a bunch of requests
    return (
      this.waitForSchoolUpdate.isRunning ||
      this.waitForMatchesUpdate.isRunning ||
      this.bulkCreateMatchTask.isRunning ||
      this.remedyTask.isRunning ||
      this.matchTask.numQueued >= 5 // capping at 5 allows any queued requests to flush out and prompt an update
    )
  }

  // Disable bulk matcher button while it is already running, or any task in the match tasks group is running, to avoid
  // situations where something already queued up for matching is then selected as part of a bulk match.
  get disableBulkButtons() {
    return this.bulkCreateMatchTask.isRunning || this.matchTask.isRunning || this.remedyTask.isRunning
  }

  @action
  setInConfig(keyPath, newValue) {
    const config = this.tableConfig
    set(config, keyPath, newValue)
  }

  /**
   * Based on the selected option we can choose if we want to create a new teacher or
   * create a match.
   *
   * @param cleverTeacher
   * @param blakeTeacherValue
   */
  @action
  async matchTeacher(cleverTeacher, blakeTeacherIdOrCreate) {
    const blakeTeacherId = blakeTeacherIdOrCreate === 'create' ? null : blakeTeacherIdOrCreate
    const flashMessages = this._getFlashMessagesForBlakeUserId('match', cleverTeacher, blakeTeacherId)

    try {
      await this.matchTask.perform({ taskType: 'createMatch', cleverTeacher, blakeTeacherId })
      this.clever.setCleverUserMatchedState(cleverTeacher, true)
      this.flashQueue.addSuccess({ subtitle: flashMessages.success })
    } catch (error) {
      if (didCancel(error)) return
      const firstError = error?.errors?.[0]
      if (firstError?.code === 'unique') {
        const { detail } = firstError
        const key = 'clever.flashMessages.teacher.create.failDetail'
        const cleverUserName = cleverTeacher.fullName
        const msg = this.intl.t(key, { cleverUserName, detail })
        this.flashQueue.addFail({ subtitle: msg })
      } else {
        this.flashQueue.addFail({ subtitle: flashMessages.fail })
        this.log.error(flashMessages.fail, { backendError: error })
      }
    }
  }

  @action
  async resetTeacher(cleverTeacher) {
    const flashMessages = this._getFlashMessagesForBlakeUserId('reset', cleverTeacher)

    try {
      await this.matchTask.perform({ taskType: 'resetMatch', cleverTeacher })
      this.clever.setCleverUserMatchedState(cleverTeacher, false)

      this.flashQueue.addSuccess({ subtitle: flashMessages.success })
    } catch (error) {
      if (didCancel(error)) return
      this.flashQueue.addFail({ subtitle: flashMessages.fail })
      this.log.error(flashMessages.fail, { backendError: error })
    }
  }

  // This will kick off the tasks to get updated school and teachers. Useful for the teacher match error remedy actions
  @action
  async refreshTeachers() {
    if (this.waitForSchoolUpdate.numQueued === 0) await this.waitForSchoolUpdate.perform()
    if (this.waitForMatchesUpdate.numQueued === 0) await this.waitForMatchesUpdate.perform()
  }
}
