import { template as template_3d987e805d794082bfef6c6b54b1513d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import BlakeColours from '@blakeelearning/blake-colours/colours';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber, formatFixedDecimal } from 'district-ui-client/utils/giraffe/formatter';
import { formattedDuration } from 'district-ui-client/utils/format-duration';
import { roundAccurately } from 'district-ui-client/utils/round';
import { type IntlService, t } from 'ember-intl';
import { FigureBoxes, type FigureBoxTheme } from 'district-ui-client/components/figure-boxes';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import type { TitleOptions, SeriesColumnOptions, Point } from 'highcharts';
import type { UIScope } from 'district-ui-client/domain/ui-scope';
import type Store from '@ember-data/store';
import { ThemeKeys } from 'district-ui-client/utils/themes';
const secondsInAnHour = 60 * 60;
/**
 * Display a chart of time on task usage
 */ export interface TimeOnTaskUsageData {
    summary: {
        student_count: number;
        total_seconds: number;
        average_seconds_per_student: Nullable<number>;
        session_count: number;
        average_sessions_per_student: Nullable<number>;
        average_seconds_per_student_per_week: Nullable<number>;
        average_seconds_per_session: Nullable<number>;
    };
    months: {
        month: string;
        seconds: Nullable<number>;
    }[];
}
interface Signature {
    Args: {
        data?: TimeOnTaskUsageData;
        uiScope: UIScope;
        mainTheme?: ThemeKeys;
    };
    Element: HTMLDivElement;
}
export class TimeOnTaskUsageChart extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    store: Store;
    get scopeName() {
        return this.args.uiScope.scope === 'school' ? this.intl.t('school') : this.intl.t('district');
    }
    get activeScopeName() {
        const { uiScope } = this.args;
        if (uiScope.scope === 'district') {
            return this.store.peekRecord('district', uiScope.id)?.name ?? '';
        }
        return this.store.peekRecord('school', uiScope.id)?.name ?? '';
    }
    get contributingStudents() {
        return this.args.data?.summary.student_count;
    }
    get totalsStats() {
        const siteCopyStatBoxPrefix = 'reporting.statBoxes.timeOnTaskUsage';
        const summaryData = this.args.data?.summary;
        const theme: FigureBoxTheme = this.args.mainTheme ?? 'default';
        const totalSeconds = summaryData?.total_seconds ?? 0;
        const averageSecondsPerStudent = summaryData?.average_seconds_per_student ?? null;
        const averageSecondsPerWeek = summaryData?.average_seconds_per_student_per_week ?? null;
        return {
            data: [
                {
                    label: `${this.scopeName} ${this.intl.t(`${siteCopyStatBoxPrefix}.totalHours`)}`,
                    value: formatNumber(totalSeconds / secondsInAnHour, 1)
                },
                {
                    label: this.intl.t(`${siteCopyStatBoxPrefix}.avgHoursPerStudent`),
                    value: averageSecondsPerStudent ? formatFixedDecimal(averageSecondsPerStudent / secondsInAnHour, 1) : '-'
                },
                {
                    label: this.intl.t(`${siteCopyStatBoxPrefix}.avgHoursPerStudentPerWeek`),
                    value: averageSecondsPerWeek ? formatFixedDecimal(averageSecondsPerWeek / secondsInAnHour, 1) : '-'
                }
            ],
            title: `${this.activeScopeName} ${this.intl.t('reporting.chartTitles.timeOnTaskTotals')}`,
            theme
        };
    }
    get sessionsStats() {
        const { intl } = this;
        const siteCopyStatBoxPrefix = 'reporting.statBoxes.timeOnTaskUsage';
        const summaryData = this.args.data?.summary;
        const totalSessions = summaryData?.session_count ?? 0;
        const averageSessionsPerStudent = summaryData?.average_sessions_per_student ?? null;
        const averageSecondsPerSession = summaryData?.average_seconds_per_session ?? null;
        const theme: FigureBoxTheme = 'orange';
        return {
            data: [
                {
                    label: `${this.scopeName} ${intl.t(`${siteCopyStatBoxPrefix}.totalSessions`)}`,
                    value: formatNumber(totalSessions, 1)
                },
                {
                    label: intl.t(`${siteCopyStatBoxPrefix}.avgSessionsPerStudent`),
                    value: averageSessionsPerStudent ? formatNumber(averageSessionsPerStudent, 1) : '-'
                },
                {
                    label: intl.t(`${siteCopyStatBoxPrefix}.avgTimeInSession`),
                    value: formattedDuration(averageSecondsPerSession, '-')
                }
            ],
            title: intl.t('reporting.chartTitles.timeOnTaskSessions'),
            theme
        };
    }
    get chartDataColor() {
        const { mainTheme } = this.args;
        if (!mainTheme || mainTheme === ThemeKeys.default) return BlakeColours.dustyBlack100;
        return {
            blue: BlakeColours.oceanyBlue300,
            green: BlakeColours.grapeyGreen300
        }[mainTheme];
    }
    get columnChartData(): SeriesColumnOptions[] {
        const monthData = this.args.data?.months;
        return [
            {
                name: 'hours',
                data: monthData?.map((md)=>roundAccurately((md.seconds ?? 0) / secondsInAnHour, 1)) ?? [],
                color: this.chartDataColor,
                type: 'column'
            }
        ];
    }
    get toolTipFormatter() {
        const hours = this.intl.t('hours');
        return function(this: Point) {
            return `${this.y} ${hours}`;
        };
    }
    get categories() {
        const monthData = this.args.data?.months ?? [];
        return monthData.map((md)=>convertDateFormat(md.month, 'yyyy-MM', 'MMMM'));
    }
    get titleOptions(): TitleOptions {
        return {
            align: 'left',
            text: this.intl.t('reporting.chartTitles.timeOnTaskUsage')
        };
    }
    static{
        template_3d987e805d794082bfef6c6b54b1513d(`
    <div class="relative" ...attributes>
      <TooltipInfo
        class="z-tooltip absolute right-3 top-3 text-xs print:hidden"
        @text={{t "reporting.tooltips.timeOnTaskUsage"}}
      />

      <div class="m-2 flex justify-between">
        <div class="w-1/2" data-test-task-usage-totals>
          <FigureBoxes
            @figures={{this.totalsStats.data}}
            @title={{this.totalsStats.title}}
            @theme={{this.totalsStats.theme}}
          />
        </div>
        <div class="w-1/2" data-test-task-usage-session>
          <FigureBoxes
            @figures={{this.sessionsStats.data}}
            @title={{this.sessionsStats.title}}
            @theme={{this.sessionsStats.theme}}
          />
        </div>
      </div>
      <div class="m-2" data-test-task-usage-students>
        {{t "reporting.summaryTitles.contributingStudents"}}:
        {{this.contributingStudents}}
      </div>
      <div class="h-60">
        <SeriesColumn
          @data={{this.columnChartData}}
          @title={{this.titleOptions}}
          @categories={{this.categories}}
          @yAxisLabel={{t "reporting.yAxisLabels.timeOnTaskUsage"}}
          @toolTipFormatter={{this.toolTipFormatter}}
        />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TimeOnTaskUsageChart;
