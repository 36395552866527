import { template as template_f93a6087c5a14828b8ea3cf22181a54e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type ContentfulClientService from 'district-ui-client/services/contentful-client';
import { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type { CleverGuidePdfData } from 'district-ui-client/services/contentful-client';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { CleverUiModal } from 'district-ui-client/components/clever-ui/modal';
import { UiButton } from 'district-ui-client/components/ui-button';
interface Signature {
    Args: {
        subscriptionType: SubscriptionType;
        hasBackLink?: boolean;
        title?: string;
        subTitle?: string;
    };
    Element: HTMLElement;
    Blocks: {
        title: [];
        default: [];
    };
}
export class CleverUiHeader extends Component<Signature> {
    @service
    contentfulClient: ContentfulClientService;
    @tracked
    helpGuideUrls: Nullable<CleverGuidePdfData> = null;
    @tracked
    isShowingHelpModal = false;
    get subscriptionType() {
        return this.args.subscriptionType;
    }
    get helpGuideUrl() {
        if (!this.subscriptionType || this.subscriptionType === SubscriptionType.Writing) return '';
        return this.helpGuideUrls?.[this.subscriptionType] ?? '';
    }
    showHelpModal = async (value: boolean)=>{
        this.helpGuideUrls ??= await this.contentfulClient.getCleverGuidePdfs();
        this.isShowingHelpModal = value;
    };
    static{
        template_f93a6087c5a14828b8ea3cf22181a54e(`
    <header class="relative mx-auto flex flex-wrap" ...attributes>
      {{#if @hasBackLink}}
        <div data-test-back-link>
          <FaIcon @icon="chevron-left" class="pr-0.5" />
          <LinkTo @route="clever.dashboard" class="hover:underline">
            {{t "clever.backToDashboardTitle"}}
          </LinkTo>
        </div>
      {{/if}}
      {{#if @subscriptionType}}
        <div class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350 ml-auto print:hidden">
          <button type="button" {{on "click" (fn this.showHelpModal true)}}>
            <span>
              {{t "clever.helpLinkText"}}
            </span>
            <FaIcon @icon="circle-question" @size="lg" class="text-juicy-orange-300 ml-0.5" />
          </button>

          <CleverUiModal
            @title={{t "clever.helpLinkDescription"}}
            @isShowingModal={{this.isShowingHelpModal}}
            @onClose={{fn this.showHelpModal false}}
          >
            <div class="body">
              {{t "clever.generalHelpText" helpGuideUrl=this.helpGuideUrl htmlSafe=true}}
            </div>
            <div class="footer">
              <UiButton class="regular" {{on "click" (fn this.showHelpModal false)}}>
                {{t "components.modalDefault.footer.close"}}
              </UiButton>
            </div>
          </CleverUiModal>
        </div>
      {{/if}}
      <div class="flex-shrink flex-grow basis-full">
        {{#if @title}}
          <h1 data-test-title class="text-center">
            {{@title}}
          </h1>
        {{else if (has-block "title")}}
          {{yield to="title"}}
        {{/if}}
        {{#if @subTitle}}
          <h2 data-test-subtitle class="mb-3 text-center text-3xl font-light">
            {{@subTitle}}
          </h2>
        {{/if}}
        {{yield}}
      </div>
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverUiHeader;
