import { template as template_f5354ad5dff24e8ea567ac36099c258f } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { type SafeString } from '@ember/template';
import { Tile, PanelTitle } from 'district-ui-client/components/section';
import { array } from '@ember/helper';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import { NoDataMessage } from 'district-ui-client/components/no-data-message';
interface Signature {
    Element: HTMLElement;
    Args: {
        tooltip?: string | SafeString;
        linkPath?: string;
        empty?: boolean;
        title?: string;
        models?: unknown[];
    };
    Blocks: {
        default: [];
    };
}
export const StaticDashboardChartWrapper: TOC<Signature> = template_f5354ad5dff24e8ea567ac36099c258f(`
  <Tile @route={{@linkPath}} @models={{if @models @models (array)}} class="flex flex-col" ...attributes>
    <div class="mb-2 flex items-center justify-between gap-4">
      <PanelTitle data-test-reporting-dash-title>{{@title}}</PanelTitle>
      <TooltipInfo @text={{@tooltip}} data-test-reporting-dash-tooltip />
    </div>

    <div data-test-dashboard-chart-inner class="shrink grow basis-0">
      {{#if @empty}}
        <NoDataMessage class="m-4" @compact={{true}} />
      {{else}}
        {{yield}}
      {{/if}}
    </div>
  </Tile>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default StaticDashboardChartWrapper;
