import { template as template_3e19b639c5ce496a9d4ce076b43c6c43 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import Alert from 'district-ui-client/components/alert';
import type AlertService from 'district-ui-client/services/alert';
export class AlertMessage extends Component {
    @service
    alert: AlertService;
    closeAlert = ()=>{
        this.alert.closeAlert();
    };
    static{
        template_3e19b639c5ce496a9d4ce076b43c6c43(`
    {{#if this.alert.alertContent}}
      <Alert
        data-test-alert-message
        @showIcon={{false}}
        @closeAlertAction={{this.closeAlert}}
        @tone={{this.alert.alertContent.type}}
      >
        {{this.alert.alertContent.message}}
      </Alert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default AlertMessage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        AlertMessage: typeof AlertMessage;
    }
}
