import { template as template_03f87e6e103a4dd091f6b599e0c69660 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import { eventValue } from 'district-ui-client/helpers/event-value';
import config from 'district-ui-client/config/environment';
export class MirageScenario extends Component<{
    Element: HTMLDivElement;
}> {
    get showScenarioSelect() {
        return config.environment === 'development';
    }
    get selectedScenario() {
        return window.localStorage.getItem('district-ui-client.mirage-scenario');
    }
    get availableScenarios() {
        return window.localStorage.getItem('district-ui-client.mirage-scenarios-all')?.split(',') ?? [];
    }
    setScenario(scenarioId: string) {
        window.localStorage.setItem('district-ui-client.mirage-scenario', scenarioId);
        window.location.replace('/');
    }
    static{
        template_03f87e6e103a4dd091f6b599e0c69660(`
    {{! template-lint-disable no-bare-strings }}
    {{#if this.showScenarioSelect}}
      <div ...attributes>
        Mirage:
        {{! template-lint-disable require-input-label }}
        <select {{on "input" (eventValue this.setScenario)}}>
          {{#each this.availableScenarios as |scenario|}}
            <option value={{scenario}} selected={{eq this.selectedScenario scenario}}>{{scenario}}</option>
          {{/each}}
        </select>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
