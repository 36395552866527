import { template as template_6491f17f51024d38917fb1c286ae1877 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import { Toast } from 'district-ui-client/components/toast';
/**
 * Uses the data from the FlashQueue service to render a list of Toast components.
 */ interface Signature {
    Element: Element;
}
export class Toaster extends Component<Signature> {
    @service
    flashQueue: FlashQueueService;
    static{
        template_6491f17f51024d38917fb1c286ae1877(`
    <flash-queue class="z-flash pointer-events-none space-y-3 print:hidden" ...attributes>
      {{#each this.flashQueue.queue as |flashObject|}}
        <Toast @flash={{flashObject}} />
      {{/each}}
    </flash-queue>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default Toaster;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Toaster: typeof Toaster;
    }
}
