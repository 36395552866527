import { template as template_599946eb8126406798c53e83cc71245c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface TabItemSignature {
    Element: HTMLButtonElement;
    Args: {
        active?: boolean;
    };
    Blocks: {
        default: [];
    };
}
export const TabItem: TOC<TabItemSignature> = template_599946eb8126406798c53e83cc71245c(`
  <button
    role="menuitem"
    type="button"
    class="group inline-block min-w-[100px] text-center"
    data-test-tab-item={{if @active "active" "inactive"}}
    ...attributes
  >
    <div class="h-1 w-full" />
    <div
      class="{{if @active 'text-blue-350' 'text-neutral-250'}}
        group-hover:bg-neutral-75 my-3 inline-block rounded-lg px-4 py-2 text-base font-semibold group-focus:bg-neutral-100"
    >{{yield}}</div>
    <div
      class="{{if @active 'bg-blue-350 rounded-t-sm' 'bg-neutral-75'}}
        h-1 w-full transition-colors duration-200 ease-in-out"
    ></div>
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface TabGroupSignature {
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}
export const TabGroup: TOC<TabGroupSignature> = template_599946eb8126406798c53e83cc71245c(`
  <nav role="menubar" class="flex" ...attributes>
    {{yield}}
    <div class="border-neutral-75 grow border-b-4" />
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
