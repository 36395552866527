import { template as template_e7c7ac970d9d4ed2994ac24e76ad0001 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { ChartsDrivingTestsTotals } from 'district-ui-client/components/charts/driving-tests/totals/component';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import type ReDrivingTestsRoute from 'district-ui-client/routes/reporting/ui-scope/re/driving-tests';
import { not } from 'ember-truth-helpers';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReDrivingTestsRoute>;
    };
}
export const ReDrivingTestsRouteTemplate: TOC<Signature> = template_e7c7ac970d9d4ed2994ac24e76ad0001(`
  <DetailedPageWrapper @empty={{not @model.drivingTestsTotals.driving_tests_totals_by_grade_by_month}}>
    {{#each @model.drivingTestsTotals.driving_tests_totals_by_grade_by_month as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsDrivingTestsTotals
          class="h-full"
          @data={{gradeData}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.drivingTestsTotals.re"}}
          @title={{t "reporting.chartTitles.drivingTestsTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReDrivingTestsRouteTemplate);
