import { template as template_7e7a0240f7304502b9d1a6ce961b24dc } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Tooltip } from 'district-ui-client/components/tooltip';
import { t } from 'ember-intl';
interface Signature {
    Element: HTMLElement;
    Args: {
        isOpen: boolean;
        onClose: () => void;
    };
    Blocks: {
        default: [];
    };
}
export const InlineDrawer: TOC<Signature> = template_7e7a0240f7304502b9d1a6ce961b24dc(`
  <aside
    data-test-inline-drawer={{if @isOpen "open" "closed"}}
    class="{{if @isOpen 'border-r-neutral-75 translate-x-0 border-r-2' 'absolute -translate-x-full'}}
      inset-y-0 h-full w-[270px] shrink-0 bg-white duration-[420ms] ease-in-out print:hidden"
    ...attributes
  >
    <ToggleButton
      data-test-inline-drawer-toggle
      class="transition-position absolute top-10 duration-300 ease-in-out print:hidden
        {{if @isOpen 'right-0' '-right-5'}}"
      @isOpen={{@isOpen}}
      {{on "click" @onClose}}
    />
    <div class="h-full overflow-y-auto overflow-x-clip px-6 pt-10">
      {{yield}}
    </div>
  </aside>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ToggleButton: TOC<{
    Element: HTMLButtonElement;
    Args: {
        isOpen?: boolean;
    };
}> = template_7e7a0240f7304502b9d1a6ce961b24dc(`
  <button
    type="button"
    class="bg-neutral-75 flex items-center justify-center
      {{if @isOpen 'rounded-l-lg' 'rounded-r-lg'}}
      py-2.5 pl-0.5 pr-0.5"
    ...attributes
  >
    {{#if @isOpen}}
      <FaIcon @icon="angle-left" class="text-neutral-250 h-4 w-4 p-0.5" />
      <Tooltip @text={{t "collapse"}} />
    {{else}}
      <FaIcon @icon="angle-right" class="text-neutral-250 h-4 w-4 p-0.5" />
      <Tooltip @text={{t "expand"}} />
    {{/if}}
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
