import { template as template_f41ff4a0be7b4ace9cba60c61acc5410 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import ExportButton, { type ExportOption } from 'district-ui-client/components/export-button/component';
import { not } from 'ember-truth-helpers';
interface Signature {
    Args: {
        viewMode: 'table' | 'chart';
        isDataPresent?: boolean;
    };
    Element: HTMLDivElement;
}
export class DetailedControls extends Component<Signature> {
    exportButtonWormhole = document.querySelector('#export-button-wormhole');
    get exportOptionsToInclude(): ExportOption[] {
        return [
            {
                id: 'csv',
                label: 'CSV',
                icon: 'file-excel',
                iconPrefix: 'far'
            },
            {
                id: 'print',
                label: 'Print',
                icon: 'print'
            }
        ];
    }
    static{
        template_f41ff4a0be7b4ace9cba60c61acc5410(`
    <div class="flex flex-wrap items-center justify-center gap-3 print:hidden" ...attributes>
      {{#if this.exportButtonWormhole}}
        {{#in-element this.exportButtonWormhole}}
          <ExportButton
            @viewMode={{@viewMode}}
            @exportOptions={{this.exportOptionsToInclude}}
            @disabled={{not @isDataPresent}}
          />
        {{/in-element}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default DetailedControls;
