import { template as template_847a27c0dbd24c3aafc0b4423ad22057 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component';
import { t } from 'ember-intl';
import type { SeriesColumnOptions, LegendOptions } from 'highcharts';
/**
 * Puts together all the pieces for the course progress totals chart.
 *

 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 120 },
 *     { category: 'student_count', count: 20 },
 *   ],
 *   months: [
 *     {
 *       count: 20,
 *       month: '2016-07',
 *     },
 *     {
 *       count: 100,
 *       month: '2016-08',
 *     },
 *   ],
 * ```
 */ export interface CourseProgressTotalsData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        count: number;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: CourseProgressTotalsData;
        product: 're' | 'rex' | 'maths';
        precinct: 'lessons' | 'reading' | 'spelling' | 'my_lessons';
        tooltip?: string;
        grade?: string;
        legend?: LegendOptions;
    };
    Element: HTMLDivElement;
}
export class ChartsCourseProgressTotals extends Component<Signature> {
    @service
    intl: IntlService;
    get color() {
        const { product, precinct } = this.args;
        switch(`${product}.${precinct}`){
            case 'maths.lessons':
                return BlakeColors.forestGreen300;
            case 're.spelling':
            case 'rex.spelling':
                return BlakeColors.grapeyGreen300;
            case 're.reading':
            case 'rex.my_lessons':
                return BlakeColors.oceanyBlue300;
            default:
                return BlakeColors.oceanyBlue300;
        }
    }
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total lessons completed and student counts, and average lessons per student.
   */ get summaryStats() {
        const summaryData = this.args.data?.summary;
        const total = summaryData?.find((d)=>d.category === 'total_count')?.count ?? 0;
        const studentCount = summaryData?.find((d)=>d.category === 'student_count')?.count ?? 0;
        const average = basicAverage(total, studentCount);
        return [
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.lessonsCompleted'),
                value: formatNumber(total, 1)
            },
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.studentCount'),
                value: formatNumber(studentCount, 1)
            },
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.perStudentCount'),
                value: formatNumber(average, 1)
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const data = this.args.data?.months?.map((m)=>m.count) ?? [];
        return [
            {
                name: 'count',
                data,
                color: this.color,
                type: 'column'
            }
        ];
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    get summaryBoxColorScheme() {
        if (this.args.precinct === 'spelling') return SummaryBoxColorScheme.Green;
        if (this.args.product === 'maths') return SummaryBoxColorScheme.DarkGreen;
        return SummaryBoxColorScheme.LightBlue;
    }
    static{
        template_847a27c0dbd24c3aafc0b4423ad22057(`
    <div data-test-course-progress-totals class="relative" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{this.summaryBoxColorScheme}}
          />
        </:left>
        <:right>
          <SeriesColumn
            @title={{t "reporting.chartTitles.courseProgressTotals"}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{@legend}}
            @yAxisLabel={{t "reporting.yAxisLabels.courseProgressTotals"}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsCourseProgressTotals;
