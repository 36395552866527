import { template as template_38fd0a9c2950454c9e3f91e76a909749 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { eq } from 'ember-truth-helpers';
interface Signature {
    Element: HTMLInputElement;
    Args: {
        borderColorClass?: string;
        icon?: string;
        iconStart?: boolean;
        iconEnd?: boolean;
        readOnly?: boolean;
    };
}
/**
 * A themed text input component, that allows for icons to be embedded within the input, without requring extra elements
 * Most "text" types should work (text, password, email, search, number, tel, ...).
 */ export const InputText: TOC<Signature> = template_38fd0a9c2950454c9e3f91e76a909749(`
  <input
    type="text"
    class="placeholder:text-neutral-250 rounded-lg border bg-white px-3 py-2 text-sm hover:bg-neutral-50
      {{if @readOnly 'border-transparent' (if @borderColorClass @borderColorClass 'border-neutral-75')}}
      {{if (eq @icon 'search') 'input-text-search-icon bg-[length:1rem] bg-no-repeat bg-origin-content'}}
      {{if @iconStart 'bg-[left_-1.5rem_center] pl-8'}}
      {{if @iconEnd 'bg-[right_-1.5rem_center] pr-8'}}"
    readonly={{@readOnly}}
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
