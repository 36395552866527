import { template as template_46dd462709ee4235a40e1abaceb2b9c0 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import { SiteHeader } from 'district-ui-client/components/site-header';
import { FeatureModal } from 'district-ui-client/components/feature-modal';
import { Toaster } from 'district-ui-client/components/toaster';
import { MirageScenario } from 'district-ui-client/components/mirage-scenario';
import { ContentfulSelect } from 'district-ui-client/components/contentful-select';
const ApplicationTemplate: TOC<unknown> = template_46dd462709ee4235a40e1abaceb2b9c0(`
  <div class="flex h-screen flex-col items-stretch bg-blue-50 print:bg-white">
    <SiteHeader class="print:bg-transparent" />
    {{outlet}}
    <FeatureModal />
  </div>

  <Toaster class="fixed right-0 top-[72px] mt-10" />

  <div class="fixed bottom-0 left-0 z-50 opacity-50 hover:opacity-100 print:hidden">
    <MirageScenario class="border border-black bg-white p-1" />
    <ContentfulSelect class="border border-black bg-white p-1" />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ApplicationTemplate);
