import { template as template_6c7a38e4841a4f5ebe8741008597d556 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLUListElement;
    Blocks: {
        default: [];
    };
}
export const ActionMenu: TOC<Signature> = template_6c7a38e4841a4f5ebe8741008597d556(`
  <ul
    role="menu"
    class="divide-dusty-black-100 border-dusty-black-100 my-px min-w-[10rem] divide-y rounded-md border bg-white shadow-xl print:hidden"
    ...attributes
  >
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionMenu;
