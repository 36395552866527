import { template as template_254e4f03264e453c95da5616e8b770a6 } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import { get } from '@ember/helper';
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { formatNumber, t } from 'ember-intl';
import type ReportingService from 'district-ui-client/services/reporting';
import { type BaseReport, type BaseReportMetaStandardsSet } from 'district-ui-client/services/reporting';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type Owner from '@ember/owner';
import { Product } from 'district-ui-client/domain/product';
interface Signature {
    Args: {
        reportData: LessonPerformanceOverviewReportData;
        isReloading?: boolean;
    };
}
export interface LessonPerformanceOverviewReportData extends BaseReport {
    overview: {
        total_students: number;
        average_lesson_performance: Nullable<DistributionBands>;
    };
    meta: BaseReportMetaStandardsSet;
}
type DistributionBands = [{
        band: '0-49';
        total_students: number;
    }, {
        band: '50-79';
        total_students: number;
    }, {
        band: '80-100';
        total_students: number;
    }];
export type BandKey = '0-49' | '50-79' | '80-100';
interface BandTheme {
    backgroundColor: string;
    icon: IconName;
    iconPrefix: IconPrefix;
    iconColor: string;
}
const distributionBandTheme: Record<BandKey, BandTheme> = {
    '0-49': {
        backgroundColor: 'bg-red-50',
        icon: 'star',
        iconPrefix: 'far',
        iconColor: 'text-red-300'
    },
    '50-79': {
        backgroundColor: 'bg-yellow-50',
        icon: 'star-half-stroke',
        iconPrefix: 'far',
        iconColor: 'text-yellow-400'
    },
    '80-100': {
        backgroundColor: 'bg-green-50',
        icon: 'star',
        iconPrefix: 'fas',
        iconColor: 'text-green-300'
    }
};
export class LessonPerformanceOverview extends Component<Signature> {
    @service
    reporting: ReportingService;
    constructor(owner: Owner, args: Signature['Args']){
        super(owner, args);
        this.reporting.makeImpression();
    }
    get isFastPhonics() {
        return this.reporting.product === Product.FP;
    }
    static{
        template_254e4f03264e453c95da5616e8b770a6(`
    <Panel data-test-lesson-performance-overview-panel class={{if @isReloading "opacity-50"}}>
      <PanelHeader>
        <PanelTitle class="flex flex-wrap justify-between">
          {{#if this.isFastPhonics}}
            {{t
              "reporting.standardsPerformance.lessonPerformanceOfStudentsPeaks"
              count=(formatNumber @reportData.overview.total_students)
            }}
          {{else}}
            {{t
              "reporting.standardsPerformance.lessonPerformanceOfStudents"
              count=(formatNumber @reportData.overview.total_students)
            }}
          {{/if}}
        </PanelTitle>
      </PanelHeader>
      <PanelBody class="flex flex-wrap gap-4">
        {{#each @reportData.overview.average_lesson_performance as |distributionItem|}}
          {{#let (get distributionBandTheme distributionItem.band) as |bandTheme|}}
            <section
              data-test-lesson-performance-overview-tile
              class="flex-shrink flex-grow basis-0 rounded-lg p-4 {{bandTheme.backgroundColor}}"
            >
              <div class="flex items-baseline">
                <FaIcon
                  @icon={{bandTheme.icon}}
                  @prefix={{bandTheme.iconPrefix}}
                  class="{{bandTheme.iconColor}} mr-3 text-xl"
                />
                <span class="mr-2 text-3xl font-semibold text-neutral-400">
                  {{formatNumber distributionItem.total_students}}
                </span>
                <span class="text-2xl font-semibold text-neutral-400">
                  {{t "reporting.standardsPerformance.students" count=distributionItem.total_students}}
                </span>
              </div>
              <div class="mt-1 text-base">
                {{t "reporting.standardsPerformance.averageScore" score=distributionItem.band}}
              </div>
            </section>
          {{/let}}
        {{/each}}
      </PanelBody>
    </Panel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
