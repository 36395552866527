import { template as template_342980be23a34377b6d709626637c8c4 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { ImportPanelStudent } from 'district-ui-client/components/import-panel-student/component';
import { ManageHeading } from 'district-ui-client/components/manage-heading';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import type ImportStudentsAsyncRoute from 'district-ui-client/routes/subscription-type/manage/students/import-students';
import { t } from 'ember-intl';
import themeKey from 'district-ui-client/helpers/theme-key';
import { Panel } from 'district-ui-client/components/panel';
import { on } from '@ember/modifier';
import UiButton from 'district-ui-client/components/ui-button';
import type ImportStudentsAsyncController from 'district-ui-client/controllers/subscription-type/manage/students/import-students';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        controller: ImportStudentsAsyncController;
        model: ModelFor<ImportStudentsAsyncRoute>;
    };
}
export const ImportStudentsRouteTemplate: TOC<Signature> = template_342980be23a34377b6d709626637c8c4(`
  <ManageHeading>{{t "subscriptionType.manageStudents.importStudents.importStudents"}}</ManageHeading>
  <RolloverAlert />

  <ImportPanelStudent
    class="my-6"
    @subscriptionType={{@controller.subscriptionType}}
    @state={{@controller.activeImportTaskInstance}}
    @uploadAction={{@controller.uploadCsv}}
    @submitAction={{@controller.submitCsv}}
    @cancelAction={{@controller.cancel}}
  />

  <Panel @theme={{themeKey @controller.subscriptionType}}>
    <h2 class="mb-5">{{t "subscriptionType.manageStudents.importStudents.howDoIImportStudents"}}</h2>
    <p>{{t "subscriptionType.manageStudents.importStudents.addOneOrMoreNewStudentsTo"}}</p>
    <div>
      <UiButton class="regular" {{on "click" @controller.downloadSampleCsv}} data-test-student-sample-csv>
        {{t "subscriptionType.manageStudents.importStudents.downloadASampleCsvFile"}}
      </UiButton>
    </div>
    <p class="mb-5 mt-11">{{t "subscriptionType.manageStudents.importStudents.theCsvFileMustBeFormatted"}}</p>
    <table class="border-dusty-black-250 w-full border print:border-black">
      <thead>
        <tr>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnA"}}
          </th>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnB"}}
          </th>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnC"}}
          </th>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnD"}}
          </th>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnE"}}
          </th>
          <th class="border-dusty-black-250 border border-b-2 print:border-black">
            {{t "subscriptionType.manageStudents.importStudents.columnF"}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentFirstName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentLastName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentGrade"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.teacherEmail"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.schoolCode"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.externalStudentId"}}</td>
        </tr>
        <tr>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentFirstName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentLastName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentGrade"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.teacherEmail"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.schoolCode"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.externalStudentId"}}</td>
        </tr>
        <tr>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentFirstName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentLastName"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.studentGrade"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.teacherEmail"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.schoolCode"}}</td>
          <td>{{t "subscriptionType.manageStudents.importStudents.externalStudentId"}}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-5">
      <small class="block">{{t "subscriptionType.manageStudents.importStudents.teacherEmailIsOptionalStu"}}</small>
      <small class="block">{{t "subscriptionType.manageStudents.importStudents.externalStudentIdIsOption"}}</small>
      <small class="mt-5 block">{{t "subscriptionType.manageStudents.importStudents.allOtherFieldsAreRequired"}}</small>
    </div>
  </Panel>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ImportStudentsRouteTemplate);
