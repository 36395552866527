import { template as template_1421014fa10e4d608cff54c72c1ea9da } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultFooter: TOC<Signature> = template_1421014fa10e4d608cff54c72c1ea9da(`
  <div ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultFooter;
